interface StatusType {
  code: string;
  color: string;
  name: string;
  value: number;
  available: boolean;
}

export interface OrderStatusesType {
  claim_statuses: StatusType[];
  internal_statuses: StatusType[];
}

export interface OrderStatusesNewResponse {
  result: OrderStatusesType;
  status: string;
}

export interface OrderPageState {
  postImg: {
    data: null | any;
    error: null | string;
    loading: boolean;
  };
  productsSearchForAnalog: {
    data: null | any;
    loading: boolean;
    error: null | string;
  };

  putOrder: {
    data: null | any;
    error: null | string;
    loading: boolean;
  };

  promoCode: {
    data: null | any;
    error: null | string;
    loading: boolean;
  };

  price: {
    data: null | any;
    error: null | string;
    loading: boolean;
  };

  priceWithQuantity: {
    data: null | any;
    error: null | string;
    loading: boolean;
  };

  allStatuses: {
    data: any;
    error: null | string;
    loading: boolean;
  };

  allPharmacyStatuses: {
    data: any;
    error: null | string;
    loading: boolean;
  };

  productTags: {
    tags: { ware_id: string; tags: { tag: number; name: string }[] }[];
    error: null | string;
    loading: boolean;
  };

  selectedProduct: string | null;
  selectedActivePharmacistOnOrder: any;
  interpayPayouts: {
    order_id: string;
    pharmacy_code: string;
    status: string;
    sum: number;
    created_at: string;
    payout_date: string;
    comment?: string;
    payout_info?: string;
  }[];

  clientComment: {
    data: any;
    error: null | string;
    loading: boolean;
  };
}

export interface AutoDeliveryRequest {
  addressFrom: string;
  commentsFrom: string;
  senderFrom: string;
  cityTo: string;
  addressTo: string;
  entranceTo: string;
  floorTo: string;
  apartmentTo: string;
  commentsTo: string;
  recipientTo: string;
  sms: boolean;
  confirm_transmission: boolean;
  deliveryMethod: string;
  link: boolean;
  code: boolean;
}

export interface AutoDeliveryRequestErrors {
  addressFrom: string;
  commentsFrom: string;
  senderFrom: string;
  cityTo: string;
  addressTo: string;
  entranceTo: string;
  floorTo: string;
  apartmentTo: string;
  commentsTo: string;
  recipientTo: string;
  sms: boolean;
  confirm_transmission: boolean;
  deliveryMethod: string;
  link: boolean;
  code: boolean;
}

export enum DeliveryType {
  DELIVERY = 'DELIVERY',
  PICK_UP = 'PICK_UP',
}

export enum PaymentMethod {
  KASPI_PAY = 'KASPI_PAY',
  ON_DELIVERY = 'ON_DELIVERY',
}

export enum TimerTypeOptions {
  notСhosen = 'notСhosen',
  firstTemplate = 'firstTemplate',
  secondTemplate = 'secondTemplate',
  comment = 'comment',
}

export enum DeliveryOptions {
  ONDEMOND = 'ONDEMOND',
  SLOT = 'SLOT',
  PEDESTRIAN = 'PEDESTRIAN',
}

export enum DeliveryOptionsNames {
  ONDEMAND = 'ONDEMAND',
  SLOT = 'SLOT',
  PEDESTRIAN = 'PEDESTRIAN',
}

export const DELIVERY_TIME_OPTIONS: {
  [key in DeliveryOptions | string]: {
    label: string;
    icon?: string;
    price?: null | number;
  };
} = {
  [DeliveryOptions.ONDEMOND]: {
    label: '30-90 минут',
    /* icon: '/taxiCar.svg',
    price: null, */
  },
  [DeliveryOptions.PEDESTRIAN]: {
    label: 'До 2 часов',
    /*    icon: '/courier.svg',
    price: null, */
  },
  [DeliveryOptions.SLOT]: {
    label: 'До 4 часов',
    /*  icon: '/courier.svg',
    price: null, */
  },
};

export interface MappedMedicineItemProps {
  id: number | string;
  sku: string;
  name: string;
  img?: string;
  description?: string;
  packing?: string;
  needsRecipe?: boolean;
  manufacturer?: string;
  price: number;
  approximatePrice: number;
  countDesired?: number;
}

export interface PharmacyMappedMedicineItemProps
  extends MappedMedicineItemProps {
  count: number;
  countDesired: number;
  servicePrice: number;
}

export interface OrderItem {
  sku: string;
  count_desired: number;
}

export interface OrderPutInfo {
  address_id: number;
  delivery_method: string;
  images: string;
  items: OrderItem[] | [];
  payment_method: string;
  source: string;
}

export interface OrderStatusesResponse {
  result: {
    code: string;
    is_deleted: boolean;
    name: string;
    type: string;
    value: number;
  }[];
  status: string;
}

export enum StatusDay {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
}

export enum StatusTimer {
  TIMER = 'timer',
  HOUR = 'hour',
}

export enum StatusRunTimer {
  RUNNING = 'running',
  STOPPED = 'stopped',
}

export enum ErrorType {
  EXPECTED_WAITING_FOR_PAYMENT = 'expected status waiting_for_payment',
  EXPECTED_WAITING_FOR_DELIVERY_PAYMENT = 'expected status waiting_for_delivery_payment',
  EXPECTED_IN_PHARMACY_ISSUED = 'expected in_pharmacy_issued',
  EXPECTED_PAID = 'expected paid',
  EXPECTED_PAID_DELIVERY = 'expected paid delivery',
}
